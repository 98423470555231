// import getConfig from 'next/config'

/**
 * Takes text and return sulugified version
 * @param {String} slug
 * @returns {String} formatted slug string
 */
function getSlug(slug = '') {
  if (!slug) return ''
  return slug
    .toLowerCase()
    .replace(/ /g, '-') // replace spaces with hyphens
    .replace(/[^\w-]+/g, '') // remove special characters
    .replace(/--/g, '-') // remove double '--' created by removing special character only words
    .replace(/^-|-$/g, '') // remove leading and trailing hyphens
}

function isExternal(url, currentURL) {
  try {
    const CURRENT_URL = new URL(currentURL)
    const URLObject = new URL(url)
    return URLObject.host != CURRENT_URL.host
  } catch (err) {
    return false
  }
}

/**
 * Replace domain name in the given asset URL to the appropriate
 * domain name for the respective asset types
 *
 * @param {string} url - The original asset URL
 * @returns {string} The modified asset URL
 */
const rewriteAssetUrl = (url) => {
  if (process.env.STORYBOOK_ENV) {
    return url
  }

  // const { publicRuntimeConfig } = getConfig()
  // const serviceName = publicRuntimeConfig?.NEXT_PUBLIC_SITE_ENVIRONMENT || ''

  // if (serviceName && serviceName !== 'achieve' && serviceName !== 'website-wfs') {
  //   return url
  // }

  // If serviceName is 'achieve', use the default prefix (empty string)
  // Otherwise, prefix static assets with `/${serviceName}`
  // const serviceNamePrefix =
  //   serviceName && serviceName !== 'achieve' && serviceName !== 'website-wfs'
  //     ? `/${serviceName}`
  //     : ''

  if (typeof url !== 'string' || !url) return ''
  // const rewrittenUrl = url
  //   .replace('https://images.ctfassets.net', `${serviceNamePrefix}/assets/images`)
  //   .replace('https://assets.ctfassets.net', `${serviceNamePrefix}/assets/files`)
  //   .replace('//images.ctfassets.net', `${serviceNamePrefix}/assets/images`)
  //   .replace('//assets.ctfassets.net', `${serviceNamePrefix}/assets/files`)

  const rewrittenUrl = url
    .replace('https://images.ctfassets.net', `/assets/images`)
    .replace('https://assets.ctfassets.net', `/assets/files`)
    .replace('//images.ctfassets.net', `/assets/images`)
    .replace('//assets.ctfassets.net', `/assets/files`)
  return rewrittenUrl
}

export { getSlug, isExternal, rewriteAssetUrl }
