import logger from '@achieve/cx-logger'

/**
 * Posts a slack alert for Apollo error.
 */
const sendApolloAlert = async (err) => {
  try {
    logger.error('Apollo Error', err)
    if (process.env?.NEXT_PUBLIC_APP_ENV !== 'dev') {
      const errorString = JSON.stringify(err, null, 2)
      await fetch(
        'https://hooks.slack.com/triggers/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',
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contentful_error_message: `
              App: ${process.env?.NEXT_PUBLIC_SITE_ENVIRONMENT || 'Achieve'}
              Environment: ${process.env?.NEXT_PUBLIC_APP_ENV},
              Apollo Error: ${errorString}`,
          }),
        }
      )
    }
  } catch (error) {
    logger.info('Unable to post slack alert for apollo error', { error })
  }
}
export { sendApolloAlert }
